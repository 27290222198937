<template>
  <div>
    <CRow>
      <CCol>
        <CCard>
          <CCardHeader>
            <div class="card-title">Ordini in corso</div>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol v-for="(date, index) in dd_dates" :key="index" md="2">
                <button
                  :class="{
                    btn: true,
                    'btn-block': true,
                    'btn-primary': date.time_limit == dd_date,
                    'btn-secondary': date.time_limit != dd_date,
                  }"
                  @click="dd_date = date.time_limit"
                >
                  {{ date.time_limit }}
                </button>
                <p>N. ordini: {{ date.count_orders }}</p>
                <p>N. prodotti: {{ date.count_prods }}</p>
                <p>N. produttori: {{ date.count_producers }}</p>
                <p>N. magazzini: {{ date.count_wh }}</p>
                <p>N. consegne: {{ date.count_islands }}</p>
                <p>Stato {{ date.tot_status }}</p>
              </CCol>
            </CRow>
            <CTabs
              v-if="dd_date_str > 0"
              add-tab-classes="mt-1"
              :activeTab.sync="activeTab"
            >
              <CTab>
                <template slot="title">
                  <CIcon name="cil-list" /> Ordini</template
                >
                <br />
                <CRow>
                  <CCol md="12" v-if="activeTab == 0">
                    <OrdersCurrentByOrder :epoch="dd_date_str" />
                  </CCol>
                </CRow>
              </CTab>
              <CTab>
                <template slot="title">
                  <CIcon name="cil-list" /> Magazzini</template
                >
                <br />
                <CRow>
                  <CCol md="12">
                    <OrdersCurrentByWh :epoch="dd_date_str" />
                  </CCol>
                </CRow>
              </CTab>
              <CTab>
                <template slot="title">
                  <CIcon name="cil-list" /> Produttori</template
                >
                <br />
                <CRow>
                  <CCol md="12">
                    <OrdersCurrentByProducer :epoch="dd_date_str" />
                  </CCol>
                </CRow>
              </CTab>
              <CTab>
                <template slot="title">
                  <CIcon name="cil-list" /> Consegne</template
                >
                <br />
                <CRow>
                  <CCol md="12">
                    <OrdersCurrentByIsland :epoch="dd_date_str" />
                  </CCol>
                </CRow>
              </CTab>
            </CTabs>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import Vue from "vue";
import { GetDataManager, ApiCall } from "../../../ds/index";
import { Query } from "@syncfusion/ej2-data";
import OrdersCurrentByOrder from "./OrdersCurrentByOrder";
import OrdersCurrentByWh from "./OrdersCurrentByWh";
import OrdersCurrentByProducer from "./OrdersCurrentByProducer";
import OrdersCurrentByIsland from "./OrdersCurrentByIsland";

export default Vue.extend({
  components: {
    OrdersCurrentByOrder,
    OrdersCurrentByWh,
    OrdersCurrentByProducer,
    OrdersCurrentByIsland,
  },
  data() {
    return {
      activeTab: 0,
      dd_dates: [],
      dd_dates_loading: false,
      dd_date: null,
    };
  },
  created() {
    console.log("ciao");
    this.dd_dates_loading = true;
    let self = this;
    ApiCall(
      GetDataManager("role_market_orders_round", [this.$store.state.role.id]),
      new Query(),
      function (e) {
        self.dd_dates = e.result;
        self.dd_dates_loading = false;
        if (self.dd_dates.length > 0) {
          self.dd_date = self.dd_dates[0].time_limit;
        }
      }
    );
  },
  computed: {
    dd_date_str() {
      if (this.dd_date) {
        var epoch = Date.parse(this.dd_date);
        return epoch;
      }
      return 0;
    },
  },
});
</script>
